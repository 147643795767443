.background {
  background-repeat: no-repeat;

  @media (min-width: $mantine-breakpoint-xs) {
    background-position-y: -180px;
  }
}

.blend {
  text-align: center;
  padding-bottom: 100px;
  height: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 40%) 0%, rgb(123 123 123 / 40%) 100%);
  background-blend-mode: darken;
}

.tagline {
  font-size: 40px;
  font-weight: 600;
  margin-top: 90px;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: 1.7em;
    margin-top: 50px;
  }
}

.subTagline {
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: 1.1em;
    margin-top: 40px;
  }
}

.joinButton {
  border-radius: var(--mantine-radius-xl);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 60px;
}

.joinButtonLabel {
  font-size: 16px;
  font-weight: 700;
}
