.header {
  /* height: rem(84px); */

  /* margin-bottom: rem(120px); */

  /* background-color: var(--mantine-color-body); */

  /* border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */
  padding-top: var(--mantine-spacing-xl);
}

.inner {
  /* height: rem(84px); */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.logo {
  width: 100px;
  margin-top: -32px;

  @media (max-width: $mantine-breakpoint-xs) {
    width: 60px;
    margin-top: -10px;
  }
}

.loginButton {
  border-radius: var(--mantine-radius-xl);
}

.loginButtonLabel {
  font-size: 15px;
}

.mobileMenu {
  &:hover {
    background-color: var(--mantine-color-dark-1);
  }
}

.mobileMenuFooter {
  position: absolute;
  bottom: 40px;
  left: 25px;
  right: 25px;
}

.joinButton {
  border-radius: var(--mantine-radius-xl);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 60px;
}

.joinButtonLabel {
  font-size: 16px;
  font-weight: 700;
}

.links {
  /* padding-top: var(--mantine-spacing-xl); */

  /* height: rem(84px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainLinks {
  justify-content: center;

  /* margin-right: calc(var(--mantine-spacing-sm) * -1); */
}

.mainLinksMobile {
  justify-content: flex-start;
}

.mainLinksDark {
  background-color: var(--mantine-color-dark-6);
  border-radius: var(--paper-radius, var(--mantine-radius-default));
}

.mainLink {
  font-size: var(--mantine-font-size-sm);
  letter-spacing: 1px;

  /* color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1)); */
  color: var(--mantine-color-white) !important;
  padding: rem(8px) var(--mantine-spacing-sm) !important;
  font-weight: 600;
  text-decoration: none;

  @mixin hover {
    /* color: light-dark(var(--mantine-color-black), var(--mantine-color-white)); */
    text-decoration: none;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-sm);
  }
}

.mainLinkText {
  padding-bottom: rem(8px);
  border-bottom: rem(3px) solid transparent !important;
  transition: border-color 100ms ease, color 100ms ease;

  &[data-active] {
    border-bottom-color: var(--mantine-color-white) !important;
  }
}

.mainLinkTextMobile {
  color: var(--mantine-color-bright) !important;

  &[data-active] {
    border-bottom-color: var(--mantine-color-adinPrimary-filled) !important;
  }
}
