/* Neutralize global.css .main-content padding */
.container {
  margin-top: -40px;
}

@media (width <= $mantine-breakpoint-xs) {
  .container {
    margin-top: -20px;
  }
}

.blogPost {
  /* Poor man's dark mode */
  :root[data-mantine-color-scheme="dark"] & {
    filter: invert(90%) hue-rotate(180deg);

    img {
      filter: invert(100%) hue-rotate(180deg);
    }
  }
}
