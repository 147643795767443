.footer {
  padding-top: calc(2 * var(--mantine-spacing-xl));
  padding-bottom: var(--mantine-spacing-xl);

  /* background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6)); */

  /* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5)); */
}

.inner {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
}

.groups {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
  gap: 40px;

  /* margin-left: auto; */
}

.link {
  display: block;

  /* color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1)); */
  font-size: var(--mantine-font-size-md);
  padding-top: rem(3px);
  padding-bottom: rem(3px);

  &:hover {
    text-decoration: underline;
  }
}

.linkIcon {
  vertical-align: middle;
  margin-right: 5px;
}

.title {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700 !important;
  font-family: "Greycliff CF", var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.afterFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);

  /* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4)); */

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.social {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-xs);
  }
}
