.card {
  transition: transform 150ms ease, box-shadow 150ms ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: --mantine-shadow-md;
  }
}

.date {
  text-transform: uppercase;
  font-weight: 700;
}

.title {
  font-weight: 700;
}
