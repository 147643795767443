.markLabel {
  font-size: var(--mantine-font-size-lg);
  margin-top: rem(8px);
}

.thumb {
  border-width: 0;
}

.trackLabel {
  font-size: var(--mantine-font-size-sm);
  padding: 10px;
}
