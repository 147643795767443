/* Neutralize global.css .main-content padding */
.page_container__UBAEG {
  margin-top: -40px;
}

@media (width <= 36em) {
  .page_container__UBAEG {
    margin-top: -20px;
  }
}

/* Poor man's dark mode */

:root[data-mantine-color-scheme="dark"] .page_blogPost__M_d9L {
    filter: invert(90%) hue-rotate(180deg);
  }

:root[data-mantine-color-scheme="dark"] .page_blogPost__M_d9L img {
      filter: invert(100%) hue-rotate(180deg);
    }

.notifications_root__U4O8K {
  background-color: var(--notification-color, var(--mantine-primary-color-filled));
}

  .notifications_root__U4O8K::before {
    background-color: var(--mantine-color-white);
  }

.notifications_description__BZAl7,
.notifications_title__nIwpg {
  color: var(--mantine-color-white);
}

.notifications_closeButton__2_hXb {
  color: var(--mantine-color-white);
}

@media (hover: hover) {

  .notifications_closeButton__2_hXb:hover {
    background-color: rgb(0 0 0 / 10%);
  }
}

@media (hover: none) {

  .notifications_closeButton__2_hXb:active {
    background-color: rgb(0 0 0 / 10%);
  }
}

.NewsletterSignUp_turnstile__DHo40 {
  font-size: 0;
}

.Footer_footer__o4_Ec {
  padding-top: calc(2 * var(--mantine-spacing-xl));
  padding-bottom: var(--mantine-spacing-xl);

  /* background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6)); */

  /* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5)); */
}

.Footer_inner__w0_wv {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 48em) {

.Footer_inner__w0_wv {
    flex-direction: column;
    align-items: center
}
  }

.Footer_groups__AWb5A {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
  gap: 40px;

  /* margin-left: auto; */
}

.Footer_link__wHCdx {
  display: block;

  /* color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1)); */
  font-size: var(--mantine-font-size-md);
  padding-top: calc(0.1875rem * var(--mantine-scale));
  padding-bottom: calc(0.1875rem * var(--mantine-scale));
}

.Footer_link__wHCdx:hover {
    text-decoration: underline;
  }

.Footer_linkIcon__W_FHo {
  vertical-align: middle;
  margin-right: 5px;
}

.Footer_title__aIcLm {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700 !important;
  font-family: "Greycliff CF", var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__aIcLm {
  color: var(--mantine-color-white);
}

.Footer_afterFooter__9Bc_b {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}

/* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4)); */

@media (max-width: 48em) {

.Footer_afterFooter__9Bc_b {
    flex-direction: column
}
  }

@media (max-width: 48em) {

.Footer_social__ZBgR2 {
    margin-top: var(--mantine-spacing-xs)
}
  }

.ColorSchemeToggle_container___3H_A:hover {
    background-color: var(--mantine-color-dark-1);
  }

.ColorSchemeToggle_icon__hAqJq {
  width: calc(1.375rem * var(--mantine-scale));
  height: calc(1.375rem * var(--mantine-scale));
}

[data-mantine-color-scheme='dark'] .ColorSchemeToggle_dark__Hnbgo {
    display: none;
}

[data-mantine-color-scheme='light'] .ColorSchemeToggle_dark__Hnbgo {
    display: block;
}

[data-mantine-color-scheme='light'] .ColorSchemeToggle_light__B5SHF {
    display: none;
}

[data-mantine-color-scheme='dark'] .ColorSchemeToggle_light__B5SHF {
    display: block;
}

.Header_header__pXml_ {
  /* height: rem(84px); */

  /* margin-bottom: rem(120px); */

  /* background-color: var(--mantine-color-body); */

  /* border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */
  padding-top: var(--mantine-spacing-xl);
}

.Header_inner__BWOOt {
  /* height: rem(84px); */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Header_logo__sUWiP {
  width: 100px;
  margin-top: -32px;
}

@media (max-width: 36em) {

.Header_logo__sUWiP {
    width: 60px;
    margin-top: -10px
}
  }

.Header_loginButton__mkv7L {
  border-radius: var(--mantine-radius-xl);
}

.Header_loginButtonLabel__7wXHg {
  font-size: 15px;
}

.Header_mobileMenu__1zlXM:hover {
    background-color: var(--mantine-color-dark-1);
  }

.Header_mobileMenuFooter__HYf_w {
  position: absolute;
  bottom: 40px;
  left: 25px;
  right: 25px;
}

.Header_joinButton__ThctX {
  border-radius: var(--mantine-radius-xl);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 60px;
}

.Header_joinButtonLabel__imFPq {
  font-size: 16px;
  font-weight: 700;
}

.Header_links__r5j1M {
  /* padding-top: var(--mantine-spacing-xl); */

  /* height: rem(84px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header_mainLinks__0_BYl {
  justify-content: center;

  /* margin-right: calc(var(--mantine-spacing-sm) * -1); */
}

.Header_mainLinksMobile__bGHzT {
  justify-content: flex-start;
}

.Header_mainLinksDark__sJgW2 {
  background-color: var(--mantine-color-dark-6);
  border-radius: var(--paper-radius, var(--mantine-radius-default));
}

.Header_mainLink__ULAcH {
  font-size: var(--mantine-font-size-sm);
  letter-spacing: 1px;

  /* color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1)); */
  color: var(--mantine-color-white) !important;
  padding: calc(0.5rem * var(--mantine-scale)) var(--mantine-spacing-sm) !important;
  font-weight: 600;
  text-decoration: none;
}

@media (hover: hover) {

  .Header_mainLink__ULAcH:hover {
    /* color: light-dark(var(--mantine-color-black), var(--mantine-color-white)); */
    text-decoration: none;
  }
}

@media (hover: none) {

  .Header_mainLink__ULAcH:active {
    /* color: light-dark(var(--mantine-color-black), var(--mantine-color-white)); */
    text-decoration: none;
  }
}

@media (max-width: 48em) {

.Header_mainLink__ULAcH {
    font-size: var(--mantine-font-size-sm)
}
  }

.Header_mainLinkText__S1YaK {
  padding-bottom: calc(0.5rem * var(--mantine-scale));
  border-bottom: calc(0.1875rem * var(--mantine-scale)) solid transparent !important;
  transition: border-color 100ms ease, color 100ms ease;
}

.Header_mainLinkText__S1YaK[data-active] {
    border-bottom-color: var(--mantine-color-white) !important;
  }

.Header_mainLinkTextMobile__0hTYV {
  color: var(--mantine-color-bright) !important;
}

.Header_mainLinkTextMobile__0hTYV[data-active] {
    border-bottom-color: var(--mantine-color-adinPrimary-filled) !important;
  }

.TennisProfile_markLabel__PJaNU {
  font-size: var(--mantine-font-size-lg);
  margin-top: calc(0.5rem * var(--mantine-scale));
}

.TennisProfile_thumb__naMHm {
  border-width: 0;
}

.TennisProfile_trackLabel__RHVUa {
  font-size: var(--mantine-font-size-sm);
  padding: 10px;
}

.HeaderLayout_background__KA82L {
  background-repeat: no-repeat;
}

  @media (min-width: 36em) {.HeaderLayout_background__KA82L {
    background-position-y: -180px
}
  }

.HeaderLayout_blend__S13BB {
  text-align: center;
  padding-bottom: 100px;
  height: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 40%) 0%, rgb(123 123 123 / 40%) 100%);
  background-blend-mode: darken;
}

.HeaderLayout_tagline__f4z7B {
  font-size: 40px;
  font-weight: 600;
  margin-top: 90px;
}

@media (max-width: 36em) {

.HeaderLayout_tagline__f4z7B {
    font-size: 1.7em;
    margin-top: 50px
}
  }

.HeaderLayout_subTagline__x2V5q {
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
}

@media (max-width: 36em) {

.HeaderLayout_subTagline__x2V5q {
    font-size: 1.1em;
    margin-top: 40px
}
  }

.HeaderLayout_joinButton__6cox8 {
  border-radius: var(--mantine-radius-xl);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 60px;
}

.HeaderLayout_joinButtonLabel__Tmaju {
  font-size: 16px;
  font-weight: 700;
}

.page_card__1lXzC {
  transition: transform 150ms ease, box-shadow 150ms ease;
}

  .page_card__1lXzC:hover {
    transform: scale(1.01);
    box-shadow: --mantine-shadow-md;
  }

.page_date__Du_Y8 {
  text-transform: uppercase;
  font-weight: 700;
}

.page_title__vXjVg {
  font-weight: 700;
}

.CmsEventPageModal_description__0QSSJ > ul {
    padding-left: 20px;
    padding-right: 20px;
  }

.NewMatchForm_markLabel__espF_ {
  margin-top: calc(0.5rem * var(--mantine-scale));
}

.NewMatchForm_thumb__laNjL {
  border-width: 0;
}

.NewMatchForm_trackLabel__VfWLK {
  font-size: var(--mantine-font-size-sm);
  padding: 10px;
}

/* Neutralize global.css .main-content padding */
.page_container__HYSIa {
  margin-top: -10px;
}

